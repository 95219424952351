<template>
  <aRow>
    <aCol
      class="content-row"
      v-for="(item, index) in theList()"
      :key="index"
      :span="24"
    >
      <aRow class="tab-header" type="flex" justify="space-between">
        <aCol>
          <h3 class="cprimary">{{ index + 1 }} - {{ item.name }}</h3>
        </aCol>
        <aCol>
          <a v-if="cancellationMode" @click="onClickOpenCancelModal(item)">
            <img
              class="mr-5"
              src="@/assets/images/dashboard/contracts/cancel-product.png"
              width="20"
              alt="icon"
            />
          </a>
        </aCol>
      </aRow>

      <ContractModalFlightRow type="view" :product="item" />
    </aCol>

    <aModal
      class="product-cancel-modal"
      :title="false"
      :visible="openCancelModal"
      :footer="false"
      :width="800"
      @cancel="onClickCloseCancelModal"
    >
      <CancelFlightModal
        :productToCancel="productToCancel"
        :tempContract="tempContract"
        :cancelSpecificProducts="cancelSpecificProducts"
      />
    </aModal>
  </aRow>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import CancelFlightModal from "../modals/CancelFlightModal.vue";
import ContractModalFlightRow from "../sections/ContractModalFlightRow.vue";

export default {
  name: "ContractFlightDetails",
  props: {
    tempContract: Object,
    cancellationMode: Boolean,
    cancelSpecificProducts: Boolean,
  },
  components: { CancelFlightModal, ContractModalFlightRow },
  mixins: [formatThings],
  data() {
    return {
      openCancelModal: false,
      productToCancel: {},
    };
  },
  methods: {
    onClickOpenCancelModal(flight) {
      this.productToCancel = flight;
      this.openCancelModal = true;
    },
    onClickCloseCancelModal() {
      this.productToCancel = {};
      this.openCancelModal = false;
    },
    thePassenger(id) {
      let travellers = JSON.parse(this.tempContract.travellers_rows);
      let theTraveller = {};

      travellers.forEach((traveller) => {
        if (traveller.id == id) {
          theTraveller.name = `${
            this.tempContract[`traveller_${traveller.id}_first_name`]
          } ${this.tempContract[`traveller_${traveller.id}_last_name`]}`;

          theTraveller.birthday = `${
            this.tempContract[`traveller_${traveller.id}_birthday`]
          }`;

          if (this.tempContract[`traveller_${traveller.id}_gender`]) {
            theTraveller.gender = `${
              this.tempContract[`traveller_${traveller.id}_gender`]
            }`;
          }
        }
      });

      return theTraveller;
    },
    theList() {
      let arr = [];
      let contractedServices = JSON.parse(
        this.tempContract.contracted_services
      );

      if (contractedServices.includes("flight")) {
        JSON.parse(this.tempContract["flight_sections_rows"]).forEach(
          (flight) => {
            let allSections = [];
            flight.sections.forEach((section) => {
              allSections.push({
                id: section.id,
                type: this.tempContract[
                  `flight_${flight.id}_section_${section.id}_type`
                ],
                origin:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_origin`
                  ],
                destination:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_destination`
                  ],
                class:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_class`
                  ],
                airline:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_airline`
                  ],
                duration:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_duration`
                  ],
                number:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_number`
                  ],
                stops:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_stops`
                  ],
                departure: `${this.formatMultiDates(
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_date`
                  ]
                )} ${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_time`
                  ]
                }`,

                arrival: `${this.formatMultiDates(
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_date`
                  ]
                )} ${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_time`
                  ]
                }`,
              });
            });

            let allTravellers = [];
            flight.travellers.forEach((traveller) => {
              allTravellers.push({
                id: traveller.id,
                ...this.thePassenger(
                  this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}`
                  ]
                ),
              });
            });

            arr.push({
              id: flight.id,
              name: `AÉREO ${this.tempContract[`flight_${flight.id}_type`]}`,
              type: this.tempContract[`flight_${flight.id}_type`],
              sections: allSections,
              travellers: allTravellers,
            });
          }
        );
      }

      return arr;
    },
  },
};
</script>

<style lang="scss">
.product-cancel-modal {
  .ant-modal-body {
    padding: 0;
    overflow: hidden;
    border-radius: 6px;
  }
  .ant-modal-close {
    margin-top: -50px;
    color: #fff;
    right: -18px;
  }
}
</style>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 13px
  font-weight: 500
  .tab-header
    margin-bottom: 15px
    color: #be4178
    text-transform: uppercase
    border-bottom: 1px solid #ececec
    h3
      font-size: 14px
      font-weight: 600
</style>
