<template>
  <aForm @submit.prevent="onSubmitForm" :form="form">
    <aRow :gutter="20">
      <aCol :span="6">
        <HayaTypeDateField
          label="Nova data de Embarque"
          :fieldKey="`flight_${product.id}_section_${rowId}_departure_date_new_typed`"
          :fieldToTransferValue="`flight_${product.id}_section_${rowId}_departure_date_new`"
          :form="form"
          :required="true"
          @onBlurHayaTypeDateField="calcDuration(rowId)"
          @onChangeDatePicker="calcDuration(rowId)"
        />

        <aFormItem class="none">
          <aInput
            v-decorator="[
              `flight_${product.id}_section_${rowId}_departure_date_new`,
            ]"
          />
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label :class="'filled'" for>Novo Horário embarque</label>
          <aInput
            class="travel-input"
            placeholder="Horário"
            @change="calcDuration(rowId)"
            v-mask="'##:##'"
            v-decorator="[
              `flight_${product.id}_section_${rowId}_departure_time_new`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Classe"
          :fieldKey="`flight_${product.id}_section_${rowId}_class_new`"
          :list="flightClasses"
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Cia"
          :fieldKey="`flight_${product.id}_section_${rowId}_airline_new`"
          :list="airlinesList"
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaTypeDateField
          label="Nova data de Desembarque"
          :fieldKey="`flight_${product.id}_section_${rowId}_arrival_date_new_typed`"
          :fieldToTransferValue="`flight_${product.id}_section_${rowId}_arrival_date_new`"
          :form="form"
          :required="true"
          @onBlurHayaTypeDateField="calcDuration(rowId)"
          @onChangeDatePicker="calcDuration(rowId)"
        />

        <aFormItem class="none">
          <aInput
            v-decorator="[
              `flight_${product.id}_section_${rowId}_arrival_date_new`,
            ]"
          />
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label :class="'filled'" for>Novo Horário Desembarque</label>
          <aInput
            class="travel-input"
            placeholder="Horário"
            @change="calcDuration(rowId)"
            v-mask="'##:##'"
            v-decorator="[
              `flight_${product.id}_section_${rowId}_arrival_time_new`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <HayaTextField
          label="Duração do voo"
          :fieldKey="`flight_${product.id}_section_${rowId}_duration_new`"
          :required="true"
          :form="form"
        />
      </aCol>

      <aCol :span="6">
        <HayaTextField
          label="Nº do vôo"
          :fieldKey="`flight_${product.id}_section_${rowId}_number_new`"
          :required="true"
          :form="form"
        />
      </aCol>
    </aRow>

    <div class="a-center">
      <aButton
        class="page-button f12 nonex mb-30"
        type="primary"
        size="small"
        html-type="submit"
        :loading="loading"
        :disabled="loading"
      >
        Salvar novos dados
      </aButton>
    </div>
  </aForm>
</template>

<script>
import { intervalToDuration } from "date-fns";
import formatThings from "@/mixins/general/formatThings";

import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaTypeDateField from "@/components/general/fields/HayaTypeDateField.vue";

export default {
  name: "ContractCancelFlightForm",
  props: {
    product: Object,
    tempContract: Object,
    rowId: Number || String,
  },
  components: { HayaTypeDateField, HayaSelectField, HayaTextField },
  mixins: [formatThings],
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      airlinesList: [],
      flightClasses: [
        { label: "ECONÔMICA", value: "ECONÔMICA" },
        { label: "ECONÔMICA PREMIUM", value: "ECONÔMICA PREMIUM" },
        { label: "EXECUTIVA", value: "EXECUTIVA" },
        { label: "FIRST CLASS", value: "FIRST CLASS" },
      ],
    };
  },
  mounted() {
    this.getAirlines();
  },
  methods: {
    getAirlines() {
      this.$http
        .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
        .then(({ data }) => {
          this.airlinesList = data.data.map(({ name, logo }) => {
            return {
              label: logo
                ? `<img class="mr-5" src="${logo}" alt="logo" width="13" /> ${name}`
                : `${name}`,
              value: name,
            };
          });
        });
    },
    calcDuration() {
      setTimeout(() => {
        let departureDate = this.form.getFieldValue(
          `flight_${this.product.id}_section_${this.rowId}_departure_date_new`
        );

        let departureTime = this.form.getFieldValue(
          `flight_${this.product.id}_section_${this.rowId}_departure_time_new`
        );

        let arrivalDate = this.form.getFieldValue(
          `flight_${this.product.id}_section_${this.rowId}_arrival_date_new`
        );

        let arrivalTime = this.form.getFieldValue(
          `flight_${this.product.id}_section_${this.rowId}_arrival_time_new`
        );

        if (departureDate && departureTime && arrivalDate && arrivalTime) {
          let interval = intervalToDuration({
            start: new Date(departureDate + " " + departureTime),
            end: new Date(arrivalDate + " " + arrivalTime),
          });

          this.form.setFieldsValue({
            [`flight_${this.product.id}_section_${this.rowId}_duration_new`]: `${this.formatDateTimeZeros(
              interval.hours
            )}:${this.formatDateTimeZeros(interval.minutes)}`,
          });
        }
      }, 200);
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-row {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-weight: 600;
  .type {
    font-size: 12px;
    color: #aaa;
  }
}
</style>
