<template>
  <aForm :form="settingsForm" @submit="submitContractSettings">
    <ContractSettingsModal
      :form="settingsForm"
      :contract="contract"
      :tempContract="tempContract"
      :disableInfoterId="true"
      :allowUserToEdit="allowUserToEdit"
    />

    <aRow class="mt-20 mb-20">
      <aCol class="a-center" :span="24">
        <aButton
          type="primary"
          html-type="submit"
          :loading="updateContractSettings"
        >
          <b class="relative">SALVAR ALTERAÇÕES</b>
        </aButton>
      </aCol>
    </aRow>
  </aForm>
</template>

<script>
import ContractSettingsModal from "@/components/contracts/modals/ContractSettingsModal.vue";

export default {
  name: "ContractModalSettingsTab",
  props: {
    tempContract: Object,
    contract: Object,
  },
  components: {
    ContractSettingsModal,
  },
  data() {
    return {
      settingsForm: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
      updateContractSettings: false,
    };
  },
  mounted() {
    this.allowUserToEdit = [1, "1", true].includes(
      this.tempContract.allow_user_edit
    )
      ? true
      : false;
  },
  methods: {
    updateTempContract(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempContract[field] = value;

      if (Array.isArray(value)) {
        this.tempContract[field] = JSON.stringify(value);
      }

      this.tempContract = { ...this.tempContract };

      this.$emit("onChangeSettingsUpdateTempContract", this.tempContract);
    },
    submitContractSettings(e) {
      e.preventDefault();

      this.settingsForm.validateFields((err, values) => {
        let updateContract = {
          id: this.tempContract.id,
          customer_id: this.tempContract.customer_id,
          company_id: values.company_id,
          sale_id: this.tempContract.sale_id,
          company_branch_id: this.tempContract.company_branch_id,
          package_type: this.tempContract.package_type,
          id_infotera: this.tempContract.id_infotera,
          user: values.user,
          company: values.company,
          company_branch: values.company_branch,
          value: this.tempContract.value,
          value_to_pay: this.tempContract.value_to_pay,
          taxes_value: this.tempContract.taxes_value,
          profit_value: this.tempContract.profit_value,
          profit_percentage: this.tempContract.profit_percentage,
          financial_cost: this.tempContract.financial_cost,
          marketing_types: this.tempContract.marketing_types,
          first_checkin: this.tempContract.first_checkin,
          last_checkout: this.tempContract.last_checkout,
          payment_methods: this.tempContract.payment_methods,
          contracted_services: this.tempContract.contracted_services,
          release_date: values.release_date,
          status: values.status,
          manual_anti_fraud: values.manual_anti_fraud,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        };

        if (!err) {
          this.updateContractSettings = true;
          this.$http
            .post("/contract-v2/update", updateContract)
            .then(({ data }) => {
              this.$message.success(data.message);

              this.$emit("onUpdateContractSettings");

              if (
                values.manual_anti_fraud !== this.tempContract.manual_anti_fraud
              ) {
                let fraudTxt = "status";
                if (values.manual_anti_fraud === "Concluída")
                  fraudTxt = "concluded";

                this.$http.post("/log/create", {
                  user_id: this.$store.state.userData.id,
                  module_id: this.tempContract.id,
                  module: "contract",
                  action: `contract-manual-anti-fraud-${fraudTxt}`,
                  description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> alterou a análise anti-fraude para <b>${values.manual_anti_fraud}</b>.`,
                });

                this.notifyUsersInContract(values.manual_anti_fraud);
              }
            })
            .finally(() => (this.updateContractSettings = false));
        }
      });
    },
    notifyUsersInContract(status) {
      this.$http
        .post("/notification/create", {
          user_id: this.tempContract.user,
          created_by: this.$store.state.userData.id,
          title: "Análise Anti-fraude manual",
          action: `/contracts/list?id=${this.tempContract.id}`,
          content: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> alterou a análise anti-fraude para <b>${status}</b>.`,
        })
        .then(({ data }) => {
          this.$http.post("/note/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            title: "Análise Anti-fraude manual",
            module: "contract",
            notification_id: data.id,
            reason: `Análise de Fraude`,
            content: `Análise anti-fraude <b>${status}</b>.`,
          });
        });
    },
  },
};
</script>