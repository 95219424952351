<template>
  <aForm @submit.prevent="onSubmitForm" :form="form">
    <aRow class="data-row mb-30">
      <aCol :span="6">
        <span class="label">Valor:</span>
        {{ flightValues().total | formatPricePtBr }}
      </aCol>

      <aCol :span="6">
        <span class="label">A pagar:</span>
        {{ flightValues().toPay | formatPricePtBr }}
      </aCol>

      <aCol :span="6">
        <span class="label">Taxas:</span>
        {{ flightValues().taxes | formatPricePtBr }}
      </aCol>

      <aCol :span="6">
        <span class="label">Luvratividade:</span>
        {{ flightValues().profit | formatPricePtBr }}
      </aCol>
    </aRow>

    <aRow class="fees" :gutter="20" align="middle">
      <aCol :span="11">
        <aCheckbox v-model="fineFee" @change="onChangeFineFee">
          Multa de cancelamento do cliente
        </aCheckbox>
        <aFormItem class="none">
          <aInput
            v-decorator="[
              `contract_finances_flight_${product.id}_fine_fee_active`,
            ]"
          />
        </aFormItem>
      </aCol>

      <aCol v-if="fineFee" :span="5">
        <HayaNumberField
          label="Porcentagem"
          :fieldKey="`contract_finances_flight_${product.id}_fine_fee_percentage`"
          :step="1"
          :preIcon="true"
          :required="false"
          :min="0"
          :max="100"
          @onChangeNumberField="onChangeFineFeePercentage"
        />
      </aCol>

      <aCol v-if="fineFee" :span="8">
        <aFormItem class="travel-input-outer">
          <label class="filled" for> Valor </label>
          <aInput
            class="travel-input"
            placeholder="Insira"
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
            }"
            v-decorator="[
              `contract_finances_flight_${product.id}_fine_fee_value`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>
    </aRow>

    <aRow class="fees" :gutter="20" align="middle">
      <aCol :span="16">
        <aRow :gutter="20" type="flex" justify="start" align="middle">
          <aCol>
            <aCheckbox
              v-model="supplierFineFee"
              @change="onChangeSupplierFineFee"
            >
              Multa do fornecedor
            </aCheckbox>
            <aFormItem class="none">
              <aInput
                v-decorator="[
                  `contract_finances_flight_${product.id}_supplier_fine_fee_active`,
                ]"
              />
            </aFormItem>
          </aCol>
          <aCol class="truncate supplier">
            {{
              tempContract[`contract_finances_flight_${product.id}_supplier`]
            }}</aCol
          >
        </aRow>
      </aCol>

      <aCol v-if="supplierFineFee" :span="8">
        <aFormItem class="travel-input-outer">
          <label class="filled" for> Valor Multa do Fornecedor </label>
          <aInput
            class="travel-input"
            placeholder="Insira"
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
            }"
            v-decorator="[
              `contract_finances_flight_${product.id}_supplier_fine_fee_value`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>
    </aRow>

    <div v-if="fineFee || supplierFineFee" class="a-center mt-10">
      <aButton
        class="page-button nonex mb-30"
        type="primary"
        html-type="submit"
        :loading="loading"
        :disabled="loading"
      >
        Salvar novos dados
      </aButton>
    </div>
  </aForm>
</template>

<script>
import { parse } from "vue-currency-input";
import HayaNumberField from "@/components/general/fields/HayaNumberField.vue";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ContractCancelFlightFinancialForm",
  props: {
    type: String,
    product: Object,
    tempContract: Object,
  },
  components: { HayaNumberField },
  mixins: [formatThings],
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      fineFee: false,
      supplierFineFee: false,
    };
  },
  mounted() {},
  methods: {
    flightValues() {
      let value = 0;
      let taxes = 0;
      let duTax = 0;
      let tebAgency = 0;

      if (this.tempContract[`flight_${this.product.id}_type`] === "Regular") {
        this.product.travellers.forEach((traveller) => {
          value += parse(
            this.tempContract[
              `flight_${this.product.id}_traveller_${traveller.id}_value`
            ]
          );

          taxes += parse(
            this.tempContract[
              `flight_${this.product.id}_traveller_${traveller.id}_taxes`
            ]
          );

          duTax += parse(
            this.tempContract[
              `flight_${this.product.id}_traveller_${traveller.id}_du_tax`
            ]
          );
          tebAgency += parse(
            this.tempContract[
              `flight_${this.product.id}_traveller_${traveller.id}_teb_agency`
            ]
          );
        });
      }

      return {
        taxes: taxes,
        profit: duTax,
        total: value + taxes + duTax + tebAgency,
        toPay: value,
      };
    },
    onChangeFineFee(value) {
      this.form.setFieldsValue({
        [`contract_finances_flight_${this.product.id}_fine_fee_active`]:
          value.target.checked,
      });
    },
    onChangeSupplierFineFee(value) {
      this.form.setFieldsValue({
        [`contract_finances_flight_${this.product.id}_supplier_fine_fee_active`]:
          value.target.checked,
      });
    },
    onChangeFineFeePercentage(value) {
      const total = this.flightValues().total;
      const newValue = value ? (total * value) / 100 : 0;
      this.form.setFieldsValue({
        [`contract_finances_flight_${this.product.id}_fine_fee_value`]: newValue
          .toFixed(2)
          .replace(".", ","),
      });
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);

        this.tempContract;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fees {
  font-size: 13px;
  padding: 0 40px 0 20px;
  color: #646464;
  min-height: 66px;
  label.ant-checkbox-wrapper {
    font-size: 13px;
    margin-top: 8px;
  }
  .supplier {
    width: 200px;
    display: inline-block;
    font-weight: 600;
    line-height: 1.9;
    margin-top: 8px;
  }
}

.data-row {
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd !important;
  margin: 0 0 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  .label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #aaa;
  }
}

.title-row {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-weight: 600;
  .type {
    font-size: 12px;
    color: #aaa;
  }
}
</style>
