var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aForm',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('aRow',{staticClass:"data-row mb-30"},[_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("Valor:")]),_vm._v("\n      "+_vm._s(_vm._f("formatPricePtBr")(_vm.flightValues().total))+"\n    ")]),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("A pagar:")]),_vm._v("\n      "+_vm._s(_vm._f("formatPricePtBr")(_vm.flightValues().toPay))+"\n    ")]),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("Taxas:")]),_vm._v("\n      "+_vm._s(_vm._f("formatPricePtBr")(_vm.flightValues().taxes))+"\n    ")]),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("Luvratividade:")]),_vm._v("\n      "+_vm._s(_vm._f("formatPricePtBr")(_vm.flightValues().profit))+"\n    ")])],1),_vm._v(" "),_c('aRow',{staticClass:"fees",attrs:{"gutter":20,"align":"middle"}},[_c('aCol',{attrs:{"span":11}},[_c('aCheckbox',{on:{"change":_vm.onChangeFineFee},model:{value:(_vm.fineFee),callback:function ($$v) {_vm.fineFee=$$v},expression:"fineFee"}},[_vm._v("\n        Multa de cancelamento do cliente\n      ")]),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `contract_finances_flight_${_vm.product.id}_fine_fee_active`,
          ]),expression:"[\n            `contract_finances_flight_${product.id}_fine_fee_active`,\n          ]"}]})],1)],1),_vm._v(" "),(_vm.fineFee)?_c('aCol',{attrs:{"span":5}},[_c('HayaNumberField',{attrs:{"label":"Porcentagem","fieldKey":`contract_finances_flight_${_vm.product.id}_fine_fee_percentage`,"step":1,"preIcon":true,"required":false,"min":0,"max":100},on:{"onChangeNumberField":_vm.onChangeFineFeePercentage}})],1):_vm._e(),_vm._v(" "),(_vm.fineFee)?_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v(" Valor ")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency",value:({
            currency: {
              prefix: '',
              suffix: '',
            },
          }),expression:"{\n            currency: {\n              prefix: '',\n              suffix: '',\n            },\n          }"},{name:"decorator",rawName:"v-decorator",value:([
            `contract_finances_flight_${_vm.product.id}_fine_fee_value`,
            {
              rules: [
                {
                  required: true,
                  message: 'Preencha o valor.',
                },
              ],
            },
          ]),expression:"[\n            `contract_finances_flight_${product.id}_fine_fee_value`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Preencha o valor.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('aRow',{staticClass:"fees",attrs:{"gutter":20,"align":"middle"}},[_c('aCol',{attrs:{"span":16}},[_c('aRow',{attrs:{"gutter":20,"type":"flex","justify":"start","align":"middle"}},[_c('aCol',[_c('aCheckbox',{on:{"change":_vm.onChangeSupplierFineFee},model:{value:(_vm.supplierFineFee),callback:function ($$v) {_vm.supplierFineFee=$$v},expression:"supplierFineFee"}},[_vm._v("\n            Multa do fornecedor\n          ")]),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `contract_finances_flight_${_vm.product.id}_supplier_fine_fee_active`,
              ]),expression:"[\n                `contract_finances_flight_${product.id}_supplier_fine_fee_active`,\n              ]"}]})],1)],1),_vm._v(" "),_c('aCol',{staticClass:"truncate supplier"},[_vm._v("\n          "+_vm._s(_vm.tempContract[`contract_finances_flight_${_vm.product.id}_supplier`]))])],1)],1),_vm._v(" "),(_vm.supplierFineFee)?_c('aCol',{attrs:{"span":8}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v(" Valor Multa do Fornecedor ")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency",value:({
            currency: {
              prefix: '',
              suffix: '',
            },
          }),expression:"{\n            currency: {\n              prefix: '',\n              suffix: '',\n            },\n          }"},{name:"decorator",rawName:"v-decorator",value:([
            `contract_finances_flight_${_vm.product.id}_supplier_fine_fee_value`,
            {
              rules: [
                {
                  required: true,
                  message: 'Preencha o valor.',
                },
              ],
            },
          ]),expression:"[\n            `contract_finances_flight_${product.id}_supplier_fine_fee_value`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Preencha o valor.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e()],1),_vm._v(" "),(_vm.fineFee || _vm.supplierFineFee)?_c('div',{staticClass:"a-center mt-10"},[_c('aButton',{staticClass:"page-button nonex mb-30",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v("\n      Salvar novos dados\n    ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }