var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aFormItem',{staticClass:"hf-wrapper hf-input-wrapper"},[(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))]):_vm._e(),_vm._v(" "),_c('aInputNumber',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.fieldKey,
      {
        initialValue: _vm.initialValue,
        rules: _vm.validationRules,
      },
    ]),expression:"[\n      fieldKey,\n      {\n        initialValue,\n        rules: validationRules,\n      },\n    ]"}],staticClass:"hf-input hf-input-number",class:_vm.fieldClass,attrs:{"placeholder":_vm.placeholder || 'Insira',"min":_vm.min,"max":_vm.max,"step":_vm.step},on:{"blur":_vm.onBlur,"change":_vm.onChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }