<template>
  <aFormItem class="hf-wrapper hf-input-wrapper">
    <label v-if="label">{{ label }}</label>
    <div class="description" v-if="description">{{ description }}</div>
    <aInputNumber
      class="hf-input hf-input-number"
      :class="fieldClass"
      :placeholder="placeholder || 'Insira'"
      v-decorator="[
        fieldKey,
        {
          initialValue,
          rules: validationRules,
        },
      ]"
      :min="min"
      :max="max"
      :step="step"
      @blur="onBlur"
      @change="onChange"
    />
  </aFormItem>
</template>

<script>
export default {
  name: "HayaValueField",
  inheritAttrs: false,
  props: {
    label: String,
    step: String || Number,
    description: String,
    fieldKey: String,
    placeholder: String,
    fieldClass: String,
    requiredMessage: String,
    required: Boolean,
    initialValue: String,
    min: Number,
    max: Number,
    form: Object,
    readonly: Boolean,
  },
  data() {
    return {
      validationRules: [],
    };
  },
  computed: {
    hasSuffixContent() {
      // Check if the 'suffix' slot has content
      return !!this.$slots["suffix"];
    },
    hasPrefixContent() {
      // Check if the 'prefix' slot has content
      return this.preIcon === true ? true : !!this.$slots["prefix"];
    },
  },
  beforeMount() {
    if (this.required) {
      this.validationRules.push({
        required: true,
        message: this.requiredMessage ? this.requiredMessage : "Obrigatório",
      });
    }
  },
  methods: {
    onChange(e) {
      this.$emit("onChangeNumberField", e);
    },
    onBlur(e) {
      this.$emit("onBlurNumberField", e);
    },
  },
};
</script>

<style lang="sass">
.hf-input-wrapper
  .ant-form-explain
    font-size: 9px !important
    top: -4px
    position: relative
.hf-input
  .ant-input-number-input
    padding: 0 !important

.hf-input.ant-input-affix-wrapper
  .ant-input
    border: 2px solid #d9d9d9
    border-radius: 0
    border-width: 0 0 2px 0
    padding-left: 21px
    &:focus
      border-right-width: 0
      outline: 0
      box-shadow: none

  .ant-input-prefix
    left: 0
</style>

<style lang="sass" scoped>
.hf-wrapper
  label
    font-size: 10px
    font-weight: 500
    color: #ccc
    line-height: 1
    text-overflow: ellipsis
    width: 100%
    white-space: nowrap
    margin-top: -10px
    margin-bottom: 2px
    display: block
  .hf-input-number
    height: 29px
  .hf-input.ant-input-affix-wrapper
    border: 0
  .ant-input-number-input
    padding: 0 !important
  .hf-input
    border: 2px solid #d9d9d9
    border-radius: 0
    border-width: 0 0 2px 0
    padding-left: 0  !important
    width: 100%
    input
      padding: 0 !important
  .description
    font-size: 9px
    line-height: 1.2
    margin: 8px  0 3px
    font-weight: 500
    border-left: 2px solid #ddd
    padding-left: 7px
</style>
