var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aForm',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":6}},[_c('HayaTypeDateField',{attrs:{"label":"Nova data de Embarque","fieldKey":`flight_${_vm.product.id}_section_${_vm.rowId}_departure_date_new_typed`,"fieldToTransferValue":`flight_${_vm.product.id}_section_${_vm.rowId}_departure_date_new`,"form":_vm.form,"required":true},on:{"onBlurHayaTypeDateField":function($event){return _vm.calcDuration(_vm.rowId)},"onChangeDatePicker":function($event){return _vm.calcDuration(_vm.rowId)}}}),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.product.id}_section_${_vm.rowId}_departure_date_new`,
          ]),expression:"[\n            `flight_${product.id}_section_${rowId}_departure_date_new`,\n          ]"}]})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Novo Horário embarque")]),_vm._v(" "),_c('aInput',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"},{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.product.id}_section_${_vm.rowId}_departure_time_new`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `flight_${product.id}_section_${rowId}_departure_time_new`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Horário"},on:{"change":function($event){return _vm.calcDuration(_vm.rowId)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('HayaSelectField',{attrs:{"label":"Classe","fieldKey":`flight_${_vm.product.id}_section_${_vm.rowId}_class_new`,"list":_vm.flightClasses,"required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('HayaSelectField',{attrs:{"label":"Cia","fieldKey":`flight_${_vm.product.id}_section_${_vm.rowId}_airline_new`,"list":_vm.airlinesList,"required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('HayaTypeDateField',{attrs:{"label":"Nova data de Desembarque","fieldKey":`flight_${_vm.product.id}_section_${_vm.rowId}_arrival_date_new_typed`,"fieldToTransferValue":`flight_${_vm.product.id}_section_${_vm.rowId}_arrival_date_new`,"form":_vm.form,"required":true},on:{"onBlurHayaTypeDateField":function($event){return _vm.calcDuration(_vm.rowId)},"onChangeDatePicker":function($event){return _vm.calcDuration(_vm.rowId)}}}),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.product.id}_section_${_vm.rowId}_arrival_date_new`,
          ]),expression:"[\n            `flight_${product.id}_section_${rowId}_arrival_date_new`,\n          ]"}]})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Novo Horário Desembarque")]),_vm._v(" "),_c('aInput',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"},{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.product.id}_section_${_vm.rowId}_arrival_time_new`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `flight_${product.id}_section_${rowId}_arrival_time_new`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Horário"},on:{"change":function($event){return _vm.calcDuration(_vm.rowId)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('HayaTextField',{attrs:{"label":"Duração do voo","fieldKey":`flight_${_vm.product.id}_section_${_vm.rowId}_duration_new`,"required":true,"form":_vm.form}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('HayaTextField',{attrs:{"label":"Nº do vôo","fieldKey":`flight_${_vm.product.id}_section_${_vm.rowId}_number_new`,"required":true,"form":_vm.form}})],1)],1),_vm._v(" "),_c('div',{staticClass:"a-center"},[_c('aButton',{staticClass:"page-button f12 nonex mb-30",attrs:{"type":"primary","size":"small","html-type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v("\n      Salvar novos dados\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }